import { HttpClient, HttpHeaders, HttpResponse, HttpRequest   } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
// import 'rxjs/add/operator/catch';
import { retry, catchError, map } from 'rxjs/operators';
// import 'rxjs/add/observable/throw';
// import { WebConfig } from './WebConfig';
import { environment } from "../environments/environment";
import { HelperService } from './auth/_helpers/storage-helper';


@Injectable()
export class AppService {
    constructor(private http: HttpClient, private _router: Router, private _helper: HelperService) { }
    public _config = environment;

    public url = this._config.LFD;

    public get(_apiUrl, item?): Observable<any> {
        return this.http.get(`${this.url}` + _apiUrl,  this._header(item)).pipe(
            map(this.extractData)
            ,catchError(this.handleError))
    }

    public post(_apiUrl, item: any): Observable<any> {
        return this.http.post(`${this.url}` + _apiUrl, item.data, this.p_header(item)).pipe(
            map(this.extractData)
            ,catchError(this.handleError));
    }

    public post_file(_apiUrl, item: any): Observable<any> {
        return this.http.post(`${this.url}` + _apiUrl, item.data, {headers: this.file_header(item), responseType: 'text' })
        .pipe(
            map(this.FileextractData)
            ,catchError(this.handleError_)
            );
    }

    public patch(_apiUrl: any, item: any): Observable<any> {
        return this.http.patch(`${this.url}` + _apiUrl, item, this._header() ).pipe(
          map(this.extractData),
          catchError(this.handleError)
        );
      }

    public put(_apiUrl, itemId: number, item: any): Observable<any> {
        return this.http.put(`${this.url}` + _apiUrl + itemId, item.data, this._header(item)).pipe(
            map(this.extractData)
            ,catchError(this.handleError));
    }

    public put1(_apiUrl, item: any): Observable<any> {
        return this.http.put(`${this.url}` + _apiUrl, item.data, this._header(item)).pipe(
            map(this.extractData)
            ,catchError(this.handleError));
    }

    public delete(_apiUrl, item: any): Observable<any> {
        return this.http.delete(`${this.url}` + _apiUrl + item, this._header()).pipe(
            map(this.extractData)
            ,catchError(this.handleError));
    }

    public FileextractData(res: any) {
        let body = res;
        return body;
    }
    public handleError_(error: Response | any) {
        return throwError(error);
    }


    _header(hparam?) {
        let user = this._helper.getFromLocal('currentUser');
        if (!!user) {
            let temp = JSON.parse(user)
            let token = temp['token'];
            const headers = new HttpHeaders(
                { 
                    // 'Access-Control-Allow-Origin': 'http://localhost:4200',
                    // 'Access-Control-Allow-Credentials': 'true',
                    // 'Access- Control-Allow-Headers': 'X-Requested-With, Content-Type',
                    // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE',
                    'Content-Type': 'application/json; charset=utf-8',
                     'Authorization': 'jwt ' + token,
                     'Signature': hparam.hmac_sig,
                    'Key': hparam.u_key,
                    'Timestamp': hparam.timestamp,
                    // 'Pragma': 'no-cache',
                    // 'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate'
                     });
            const options = { headers: headers };
            return options;
        }
    }

    p_header(itm) {
        let user = this._helper.getFromLocal('currentUser');
        if (!!user) {
            let temp = JSON.parse(user)
            let token = temp['token'];
            const headers = new HttpHeaders(
                { 
                    // 'Access-Control-Allow-Origin': 'http://localhost:4200',
                    // 'Access-Control-Allow-Credentials': 'true',
                    // 'Access- Control-Allow-Headers': 'X-Requested-With, Content-Type',
                    // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE',
                    'Content-Type': 'application/json; charset=utf-8',
                     'Authorization': 'jwt ' + token,
                    'Signature': itm.hmac_sig,
                    'Key': itm.u_key,
                    'timestamp': itm.timestamp,
                    'Pragma': 'no-cache',
                    'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate'  });
            const options = { headers: headers };
            return options;
        }
    }

    file_header(itm) {
        let user = this._helper.getFromLocal('currentUser');
        if (!!user) {
            let temp = JSON.parse(user)
            let token = temp['token'];
            const headers = new HttpHeaders(
                { 
                    // 'Access-Control-Allow-Origin': 'http://localhost:4200',
                    // 'Access-Control-Allow-Credentials': 'true',
                    // 'Access- Control-Allow-Headers': 'X-Requested-With, Content-Type',
                    // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE',
                    'Content-Type': 'application/json; charset=utf-8',
                     'Authorization': 'jwt ' + token,
                    'Signature': itm.hmac_sig,
                    'Key': itm.u_key,
                    'timestamp': itm.timestamp,
                    // 'Accept' : 'application/pdf',
                    'Pragma': 'no-cache',
                    'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate'  });
            const options = headers;
            return options;
        }
    }


    public extractData(res: Response) {
        let body = res;
        return body;
    }
    public handleError(error: Response | any) {
        return throwError(error);
    }



}


