import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "./_services/authentication.service";
import { AlertService } from "./_services/alert.service";

import { NgxUiLoaderService } from "ngx-ui-loader";
import {
  HmacGenerationService,
  ComponentCommunicationService,
} from "./_services";
import { ApiService } from "../pages/api.service";
import { ToastrService } from "ngx-toastr";
import { HelperService } from "./_helpers/storage-helper";

@Component({
  selector: ".m-grid.m-grid--hor.m-grid--root.m-page.h-100  ",
  templateUrl: "./templates/login-1.component.html",
  styleUrls: ["./templates/login.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AuthComponent implements OnInit {
  year: any = new Date().getFullYear();
  model: any = {};
  loading = false;
  error = "";
  returnUrl: "dashboard";
  public disclaimer = false;
  secret_key;
  user_key;
  suberror = [];
  color = "accent";
  checked = false;
  disabled = false;
  updateuser = {
    is_active: true,
  };
  // @ViewChild('alertSignin',
  //     { read: ViewContainerRef }) alertSignin: ViewContainerRef;
  // @ViewChild('alertSignup',
  //     { read: ViewContainerRef }) alertSignup: ViewContainerRef;
  // @ViewChild('alertForgotPass',
  //     { read: ViewContainerRef }) alertForgotPass: ViewContainerRef;

  constructor(
    private _router: Router,
    // private _script: ScriptLoaderService,
    private _route: ActivatedRoute,
    private _authService: AuthenticationService,
    private _alertService: AlertService,
    private cfr: ComponentFactoryResolver,
    private ngxService: NgxUiLoaderService,
    private hmacgen: HmacGenerationService,
    private Service: ApiService,
    private toastr: ToastrService,
    private cs: ComponentCommunicationService,
    private _helper: HelperService
  ) {
    // this.cs.themestatus = this.updateuser.is_active;
    this._helper.setInlocal("themestatus", this.updateuser.is_active);
  }
  ngOnInit() {
    this.model.remember = true;
    this.returnUrl = this._route.snapshot.queryParams["returnUrl"] || "/";
    this._router.navigate([this.returnUrl]);
  }

  test(s) {
    // console.log(this.updateuser.is_active);
    // this.cs.themeFunc(this.updateuser.is_active);
    this._helper.setInlocal("themestatus", s);
  }

  signin(formm) {
    this.ngxService.start();
    this.suberror = [];

    if (!this._helper.getFromLocal("themestatus")) {
      this._helper.setInlocal("themestatus", this.updateuser.is_active);
    }

    this.hmacgen
      .sample_hmac_gen(
        {
          user_seckey: this.secret_key,
          user_lkey: this.user_key,
        },
        JSON.stringify({ name: "Asif ali zardari" })
      )
      .subscribe(
        (ress) => {
          let fdata = {
            data: { name: "Asif ali zardari" },
            hmac_sig: ress.h_macc,
            u_key: ress.u_lkey,
            timestamp: ress.timestamp,
          };

          this._authService
            .login(this.model.username, this.model.password)
            .subscribe(
              (data) => {
                if (data) {
                  this._helper.setInlocal("currentUser", data);
                  // localStorage.setItem("currentUser", JSON.stringify(data));
                  this._helper.setInlocal("user_seckey", this.secret_key);
                  this._helper.setInlocal("user_lkey", this.user_key);

                  // localStorage.setItem(
                  //   "user_seckey",
                  //   JSON.stringify(this.secret_key)
                  // );
                  // localStorage.setItem(
                  //   "user_lkey",
                  //   JSON.stringify(this.user_key)
                  // );

                  this.Service.namesearch(fdata).subscribe(
                    (res) => {
                      this._router.navigate(["dashboard"]);
                      this.ngxService.stop();
                    },
                    (error) => {
                      this.suberror = [];

                      //  for (const property in error.error) {
                      //   this.suberror.push({
                      //     typ: property,
                      //     err: error.error[property]
                      //   });
                      // }
                      this.suberror.push({
                        typ: "Error",
                        err: error.error.error,
                      });
                      this._helper.removeFromLocal("currentUser");
                      this._helper.removeFromLocal("user_seckey");
                      this._helper.removeFromLocal("user_lkey");
                      this._helper.removeFromLocal("themestatus");
                      // localStorage.removeItem("currentUser");
                      // localStorage.removeItem(
                      //   "user_seckey"
                      // );
                      // localStorage.removeItem(
                      //   "user_lkey"

                      // );
                      // this._router.navigate(["../logout"]);
                      // this.toastr.error(error.error.error);

                      this.ngxService.stop();
                    }
                  );

                  // Helpers.setLoading(false);
                  // this._router.navigate(["dashboard"]);

                  // this.ngxService.stop();
                }
              },
              (error) => {
                this.suberror = [];

                if (error.status == 0) {
                  this.showAlert(
                    "Service Temporarily Unavailable, please try again "
                  );
                  this.outsession(error.status);
                  // for (const property in error.error) {
                  this.suberror.push({ err: "Unknown Error" });
                  //   }
                } else if (error.status == 400 || error.status == 401) {
                  this.showAlert("Invalid Credentials");
                  for (const property in error.error) {
                    this.suberror.push({
                      typ: property,
                      err: error.error[property],
                    });
                  }
                } else {
                  this.showAlert("Internal Server Error");
                  this.suberror.push({ err: "Internal Server Error" });
                }

                this.ngxService.stop();
              }
            );
        },
        (err) => {
          this.suberror = [];
          this.ngxService.stop();
          this.suberror.push({ err: "Something went wrong" });
        }
      );
    // this.removeAlert();
    // Helpers.setLoading(true);
  }

  outsession(data) {
    if (data == 0) {
      // localStorage.removeItem('currentUser');
      this._helper.removeFromLocal("currentUser");

      this._router.navigate(["/login"]);
    }
  }

  alertpopup = false;
  messageAlert = "";

  removeAlert() {
    this.alertpopup = false;
    this.error = "";
  }

  showAlert(target) {
    this.alertpopup = true;
    this.error = target;
  }
}
