import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
// import { MockBackend } from '@angular/http/testing';

import { AuthRoutingModule } from "./auth-routing.routing";
import { AuthComponent } from "./auth.component";
import { AlertComponent } from "./_directives/alert.component";
import { LogoutComponent } from "./logout/logout.component";
import { AuthGuard } from "./_guards/auth.guard";
import { AlertService } from "./_services/alert.service";
import { AuthenticationService } from "./_services/authentication.service";
import { JwtInterceptor } from "./interceptors/jwt.interceptor";

import { NgxUiLoaderModule } from "ngx-ui-loader";
import { HmacGenerationService, IPAddressService } from "./_services";
// import { fakeBackendProvider } from './_helpers/index';
// import { WebConfig } from '../WebConfig';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material";
import { MatSliderModule } from "@angular/material/slider";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatCardModule } from "@angular/material/card";

@NgModule({
  declarations: [AuthComponent, AlertComponent, LogoutComponent],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    AuthRoutingModule,
    NgxUiLoaderModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSliderModule,
    MatCheckboxModule,
    MatCardModule
  ],
  providers: [
    AuthGuard,
    AlertService,
    AuthenticationService,

    IPAddressService,
    HmacGenerationService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // api backend simulation
    // fakeBackendProvider,
    // MockBackend,
    // BaseRequestOptions,
    // WebConfig,
  ],
  // entryComponents: [AlertComponent],
})
export class AuthModule {}
