import { Injectable, EventEmitter, Output } from "@angular/core";
import { Subject, Observable, BehaviorSubject, ReplaySubject } from "rxjs";
// import { Observable } from 'rxjs';
// import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComponentCommunicationService {
  public searchsource$: ReplaySubject<object> = new ReplaySubject(1);
  // public themesource$: Subject<boolean> = new Subject();
  public detailsdata: Object;
  public stext: String;
  public themestatus: boolean;
  // public rightPanelStatus;
  searchtext$ = this.searchsource$.asObservable();
  // themetext$ = this.themesource$.asObservable();
  constructor() {}

  public dataEmitter(a, text) {
    // this.rightPanelStatus = true;
    // a.rightPanelStatus = false;
    // a.rsOpenStatus = true;
    this.stext = text;
   
    this.searchsource$.next(a);
   
    

  }


  // public themeFunc(b){
  //   this.themesource$.next(b);
  // }


}
