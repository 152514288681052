import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
})
export class HelperService {
   

    constructor(){}

    getFromLocal(text){
        return sessionStorage.getItem(text);
     }
 
     setInlocal(text, data){
         return sessionStorage.setItem(text, JSON.stringify(data));
          
     }

     removeFromLocal(_text){
        sessionStorage.removeItem(_text);
     }
 
}