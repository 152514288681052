import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "ng-nav";
  username = "rishi";
  width: number = 100;
  height: number = 100;
  myStyle: object = {};
  public myParams: object = {};
  constructor(private router: Router) {}
  ngOnInit() {
    //particles
    this.myStyle = {
      position: "fixed",
      width: "100%",
      height: "100%",
      "z-index": 1,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    };

    this.myParams = {
      particles: {
        number: {
          value: 300,
          density: {
            enable: true,
            value_area: 800,
          },
        },
        color: {
          value: "#42474f",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
        },
        size: {
          value: 3,
          random: true,
          anim: {
            enable: false,
            speed: 80,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 90,
          color: "#42474f",
          opacity: 0.85,
          width: 1,
        },
      },
      interactivity: {
        attract_enable: true,
        detect_on: "canvas",
        events: {
          onhover: {
            enable: false,
            mode: "repulse",
          },
          onclick: {
            enable: false,
            mode: "push",
          },
          mode:{

          },
          resize: false,
        },
      },
      retina_detect: true,
    };
    this.router.navigate(['']);
  }
}
