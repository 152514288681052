import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { HelperService } from '../_helpers/storage-helper';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private _router: Router, private _helper: HelperService) {
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (!!this._helper.getFromLocal('currentUser') == true) {
            return true;
        } 
            this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        
    }
    // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    //     let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    //     return this._userService.verify().map(
    //         data => {
    //             if (data !== null) {
    //                 // logged in so return true
    //                 return true;
    //             }
    //             // error when verify so redirect to login page with the return url
    //             this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    //             return false;
    //         },
    //         error => {
    //             // error when verify so redirect to login page with the return url
    //             this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    //             return false;
    //         });
    // }
}