import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient, HttpRequest,  HttpResponse } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class IPAddressService {
    public _config = environment;

    constructor(private http: HttpClient) {
    }

    getIpAddress() {
        return this.http
              .get('https://api.ipify.org/?format=json')
              .pipe(
                map(this.extractData)

               , catchError(this.handleError)
              );
      }
      
      
      public extractData(res: Response) {
        let body = res;
        return body;
    }
    public handleError(error: Response | any) {
        return throwError(error);
    }

   
}