import { Injectable } from "@angular/core";
import {  HttpClient , HttpResponse,  HttpHeaders,  HttpRequest } from "@angular/common/http";
import { Router } from '@angular/router';
// import { WebConfig } from '../../WebConfig';
// import { Helpers } from '../../helpers';
import { Observable, throwError  } from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import { environment } from "../../../environments/environment";
import { HelperService } from '../_helpers/storage-helper';
// import { LayoutService } from 'src/app/shared/services/layout.service';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/observable/throw';
// import { map } from 'rxjs/operators';

@Injectable()
export class AuthenticationService {
public config = environment;
    constructor(private http: HttpClient, private _helper: HelperService) { }
    login(username: string, password: string) {

        let url = this.config.LFD + 'api-token-auth/';
        // let headers = new Headers({ 'Content-Type': 'application/json' });
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Pragma': 'no-cache',
              'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate'
            })
          };
        return this.http.post(url, { username: username, password: password }, httpOptions)
            .pipe(
                map((res: Response) => { 
                    return res;
                 })
            ,catchError((e: any, caught) => throwError(this.errorHandler(e))))
    }

    errorHandler(error: any): void {
        return error;
    }

    isAuth() {
        const user = this._helper.getFromLocal('currentUser');
        return !!user;
    }
    logout() {

        this._helper.removeFromLocal('currentUser');
        this._helper.removeFromLocal('user_seckey');
        this._helper.removeFromLocal('user_lkey');
        this._helper.removeFromLocal('themestatus');
        
        // this.layoutService.rsToggleStatus.next(false);

    }
}