import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LogoutComponent } from "./auth/logout/logout.component";

const routes: Routes = [
  { path: "login", loadChildren: "./auth/auth.module#AuthModule" },
  { path: "logout", component: LogoutComponent },
  {
    path: "pages",
    loadChildren: "./pages/pages.module#PagesModule",
  },
  {
    path: "",
    loadChildren: "./pages/pages.module#PagesModule",
  },
  {
    path: "**",
    redirectTo: "",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      initialNavigation: "enabled",
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
