import { Injectable } from "@angular/core";
import {
  HttpHeaders,
  HttpClient,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
// const CryptoJS = require("crypto-js");
import * as CryptoJS from "crypto-js";

import Base64 from "crypto-js/enc-base64";
import { environment } from "src/environments/environment";

import { Observable, observable } from "rxjs";
import { IPAddressService } from './getIPaddress.service';
import { HelperService } from '../_helpers/storage-helper';

@Injectable()
export class HmacGenerationService {
  public _config = environment;

  constructor(private http: HttpClient, private ipaddress: IPAddressService, private _helper: HelperService) {}

  // verify() {
  //     return this.http.get('/api/verify', this.jwt()).map((response: Response) => response.json());
  // }

  hmac_gen(data) {
    
    return Observable.create(observer => {

        this.ipaddress.getIpAddress().subscribe(
            res => {
              let user_seckey = JSON.parse(this._helper.getFromLocal("user_seckey"));
              let user_lkey = JSON.parse(this._helper.getFromLocal("user_lkey"));
              let my_ip;
      
              my_ip = res["ip"];
              let method = "POST";
              let end_point = "/api/search/";
              let timestamp = new Date().toISOString();
              let dd = `${method}\n${my_ip}\n${end_point}\n${timestamp}\n${data}`;
              
              let hmac = CryptoJS.algo.HMAC.create(
                CryptoJS.algo.SHA256,
                `${user_seckey}`
              );
              hmac.update(dd);
              let hmacc = Base64.stringify(hmac.finalize());
              
              observer.next({
                h_macc: hmacc,
                u_lkey: user_lkey,
                timestamp: timestamp
              })
              observer.complete();
              
            },
            err => {
                observer.next(err)
                observer.complete();
            }
          );

    })
    

    // return d_data;
  }

  download_profile_hmac_gen(data) {
    
    return Observable.create(observer => {

        this.ipaddress.getIpAddress().subscribe(
            res => {
              let user_seckey = JSON.parse(this._helper.getFromLocal("user_seckey"));
              let user_lkey = JSON.parse(this._helper.getFromLocal("user_lkey"));
              let my_ip;
      
              my_ip = res["ip"];
              let method = "POST";
              let end_point = "/api/generate_entity_report/";
              let timestamp = new Date().toISOString();
              let dd = `${method}\n${my_ip}\n${end_point}\n${timestamp}\n${data}`;
              
              let hmac = CryptoJS.algo.HMAC.create(
                CryptoJS.algo.SHA256,
                `${user_seckey}`
              );
              hmac.update(dd);
              let hmacc = Base64.stringify(hmac.finalize());
              
              observer.next({
                h_macc: hmacc,
                u_lkey: user_lkey,
                timestamp: timestamp
              })
              observer.complete();
              
            },
            err => {
                observer.next(err)
                observer.complete();
            }
          );

    })
    

    // return d_data;
  }

  org_hmac_gen(data) {
    
    return Observable.create(observer => {

        this.ipaddress.getIpAddress().subscribe(
            res => {
              let user_seckey = JSON.parse(this._helper.getFromLocal("user_seckey"));
              let user_lkey = JSON.parse(this._helper.getFromLocal("user_lkey"));
              let my_ip;
      
              my_ip = res["ip"];
              let method = "POST";
              let end_point = "/api/search_entities/";
              let timestamp = new Date().toISOString();
              let dd = `${method}\n${my_ip}\n${end_point}\n${timestamp}\n${data}`;
              
              let hmac = CryptoJS.algo.HMAC.create(
                CryptoJS.algo.SHA256,
                `${user_seckey}`
              );
              hmac.update(dd);
              let hmacc = Base64.stringify(hmac.finalize());
              
              observer.next({
                h_macc: hmacc,
                u_lkey: user_lkey,
                timestamp: timestamp
              })
              observer.complete();
              
            },
            err => {
                observer.next(err)
                observer.complete();
            }
          );

    })
    

    // return d_data;
  }

  sample_hmac_gen(keys, data) {

    return Observable.create(observer => {
        this.ipaddress.getIpAddress().subscribe(
            res => {
              let user_seckey = keys.user_seckey;
              let user_lkey = keys.user_lkey;
              let my_ip;
      
              my_ip = res["ip"];
              // my_ip = '192.168.137.4';
              let method = "POST";
              let end_point = "/api/search/";
              // let timestamp = new Date().toISOString();
              let timestamp = '2019-11-25T10:55:51Z';
              let dd = `${method}\n${my_ip}\n${end_point}\n${timestamp}\n${data}`;
              
              let hmac = CryptoJS.algo.HMAC.create(
                CryptoJS.algo.SHA256,
                `${user_seckey}`
              );
              hmac.update(dd);
              
              let hmacc = Base64.stringify(hmac.finalize());
              
              observer.next({
                h_macc: hmacc,
                u_lkey: user_lkey,
                timestamp: timestamp
              })
              observer.complete();
              
            },
            err => {
                observer.next(err)
                observer.complete();
            }
          );
    })

    // let user_seckey = keys.user_seckey;
    // let user_lkey = keys.user_lkey;
    // let my_ip = this.publicip;
    // let method = "POST";
    // let end_point = "/api/search_fuzzy/";
    // let timestamp = new Date().toISOString();
    // let dd = `${method}\n${my_ip}\n${end_point}\n${timestamp}\n${data}`;

    // let hmac = CryptoJS.algo.HMAC.create(
    //   CryptoJS.algo.SHA256,
    //   `${user_seckey}`
    // );
    // hmac.update(dd);
    // let hmacc = Base64.stringify(hmac.finalize());
    // return {
    //   h_macc: hmacc,
    //   u_lkey: user_lkey,
    //   timestamp: timestamp
    // };
  }

  person_search_hmac_gen(data) {

    return Observable.create(observer => {
        this.ipaddress.getIpAddress().subscribe(
            res => {
              let user_seckey = JSON.parse(this._helper.getFromLocal("user_seckey"));
              let user_lkey = JSON.parse(this._helper.getFromLocal("user_lkey"));
              let my_ip;
      
              my_ip = res["ip"];
              // my_ip = '192.168.137.4';
              let method = "POST";
              let end_point = "/api/search/";
              // let timestamp = new Date().toISOString();
              let timestamp = '2019-11-25T10:55:51Z';
              let dd = `${method}\n${my_ip}\n${end_point}\n${timestamp}\n${data}`;
              
              let hmac = CryptoJS.algo.HMAC.create(
                CryptoJS.algo.SHA256,
                `${user_seckey}`
              );
              hmac.update(dd);
              
              let hmacc = Base64.stringify(hmac.finalize());
              
              observer.next({
                h_macc: hmacc,
                u_lkey: user_lkey,
                timestamp: timestamp
              })
              observer.complete();
              
            },
            err => {
                observer.next(err)
                observer.complete();
            }
          );
    })

    
  }

  post_delta_hmac_gen(data) {

    return Observable.create(observer => {
        this.ipaddress.getIpAddress().subscribe(
            res => {
              let user_seckey = JSON.parse(this._helper.getFromLocal("user_seckey"));
              let user_lkey = JSON.parse(this._helper.getFromLocal("user_lkey"));
              let my_ip;
      
              my_ip = res["ip"];
              // my_ip = '192.168.137.4';
              let method = "POST";
              let end_point = "/api/get_delta/";
              // let timestamp = new Date().toISOString();
              let timestamp = '2019-11-25T10:55:51Z';
              let dd = `${method}\n${my_ip}\n${end_point}\n${timestamp}\n${data}`;
              let hmac = CryptoJS.algo.HMAC.create(
                CryptoJS.algo.SHA256,
                `${user_seckey}`
              );
              hmac.update(dd);
              
              let hmacc = Base64.stringify(hmac.finalize());
              
              observer.next({
                h_macc: hmacc,
                u_lkey: user_lkey,
                timestamp: timestamp
              })
              observer.complete();
              
            },
            err => {
                observer.next(err)
                observer.complete();
            }
          );
    })

    
  }

  validation_rule_hmac_gen() {

    return Observable.create(observer => {
        this.ipaddress.getIpAddress().subscribe(
            res => {
              let user_seckey = JSON.parse(this._helper.getFromLocal("user_seckey"));
              let user_lkey = JSON.parse(this._helper.getFromLocal("user_lkey"));
              let my_ip;
      
              my_ip = res["ip"];
              // my_ip = '192.168.137.4';
              let method = "GET";
              let end_point = "/api/validation_rules/";
              // let timestamp = new Date().toISOString();
              let timestamp = '2019-11-25T10:55:51Z';
              let dd = `${method}\n${my_ip}\n${end_point}\n${timestamp}\n`;
              
              let hmac = CryptoJS.algo.HMAC.create(
                CryptoJS.algo.SHA256,
                `${user_seckey}`
              );
              hmac.update(dd);
              
              let hmacc = Base64.stringify(hmac.finalize());
              
              observer.next({
                h_macc: hmacc,
                u_lkey: user_lkey,
                timestamp: timestamp
              })
              observer.complete();
              
            },
            err => {
                observer.next(err)
                observer.complete();
            }
          );
    })

    
  }

  watchlist_options_hmac_gen() {

    return Observable.create(observer => {
        this.ipaddress.getIpAddress().subscribe(
            res => {
              let user_seckey = JSON.parse(this._helper.getFromLocal("user_seckey"));
              let user_lkey = JSON.parse(this._helper.getFromLocal("user_lkey"));
              let my_ip;
      
              my_ip = res["ip"];
              // my_ip = '192.168.137.4';
              let method = "GET";
              let end_point = "/api/get_watchlist/";
              // let timestamp = new Date().toISOString();
              let timestamp = '2019-11-25T10:55:51Z';
              let dd = `${method}\n${my_ip}\n${end_point}\n${timestamp}\n`;
              
              let hmac = CryptoJS.algo.HMAC.create(
                CryptoJS.algo.SHA256,
                `${user_seckey}`
              );
              hmac.update(dd);
              
              let hmacc = Base64.stringify(hmac.finalize());
              
              observer.next({
                h_macc: hmacc,
                u_lkey: user_lkey,
                timestamp: timestamp
              })
              observer.complete();
              
            },
            err => {
                observer.next(err)
                observer.complete();
            }
          );
    })

    
  }

  watchlist_dropdown_options_hmac_gen() {

    return Observable.create(observer => {
        this.ipaddress.getIpAddress().subscribe(
            res => {
              let user_seckey = JSON.parse(this._helper.getFromLocal("user_seckey"));
              let user_lkey = JSON.parse(this._helper.getFromLocal("user_lkey"));
              let my_ip;
      
              my_ip = res["ip"];
              // my_ip = '192.168.137.4';
              let method = "GET";
              let end_point = "/api/watchlistoptions/";
              // let timestamp = new Date().toISOString();
              let timestamp = '2019-11-25T10:55:51Z';
              let dd = `${method}\n${my_ip}\n${end_point}\n${timestamp}\n`;
              
              let hmac = CryptoJS.algo.HMAC.create(
                CryptoJS.algo.SHA256,
                `${user_seckey}`
              );
              hmac.update(dd);
              
              let hmacc = Base64.stringify(hmac.finalize());
              
              observer.next({
                h_macc: hmacc,
                u_lkey: user_lkey,
                timestamp: timestamp
              })
              observer.complete();
              
            },
            err => {
                observer.next(err)
                observer.complete();
            }
          );
    })

    
  }

 
  watchlist_hmac_gen() {

    return Observable.create(observer => {
        this.ipaddress.getIpAddress().subscribe(
            res => {
              let user_seckey = JSON.parse(this._helper.getFromLocal("user_seckey"));
              let user_lkey = JSON.parse(this._helper.getFromLocal("user_lkey"));
              let my_ip;
      
              my_ip = res["ip"];
              // my_ip = '192.168.137.4';
              let method = "GET";
              let end_point = "/api/watchlists/";
              // let timestamp = new Date().toISOString();
              let timestamp = '2019-11-25T10:55:51Z';
              let dd = `${method}\n${my_ip}\n${end_point}\n${timestamp}\n`;
              
              let hmac = CryptoJS.algo.HMAC.create(
                CryptoJS.algo.SHA256,
                `${user_seckey}`
              );
              hmac.update(dd);
              
              let hmacc = Base64.stringify(hmac.finalize());
              
              observer.next({
                h_macc: hmacc,
                u_lkey: user_lkey,
                timestamp: timestamp
              })
              observer.complete();
              
            },
            err => {
                observer.next(err)
                observer.complete();
            }
          );
    })

    
  }


  assets_hmac_gen(data) {

   return Observable.create(observer => {
        this.ipaddress.getIpAddress().subscribe(
            res => {
              let user_seckey = JSON.parse(this._helper.getFromLocal("user_seckey"));
              let user_lkey = JSON.parse(this._helper.getFromLocal("user_lkey"));
              let my_ip;
      
              my_ip = res["ip"];
              let method = "POST";
              let end_point = "/api/get_assets/";
              let timestamp = new Date().toISOString();
              let dd = `${method}\n${my_ip}\n${end_point}\n${timestamp}\n${data}`;
              
              let hmac = CryptoJS.algo.HMAC.create(
                CryptoJS.algo.SHA256,
                `${user_seckey}`
              );
              hmac.update(dd);
              let hmacc = Base64.stringify(hmac.finalize());
              
              observer.next({
                h_macc: hmacc,
                u_lkey: user_lkey,
                timestamp: timestamp
              })
              observer.complete();
              
            },
            err => {
                observer.next(err)
                observer.complete();
            }
          );
    })

    
  }

  worksummary_hmac_gen(data) {

    return Observable.create(observer => {
        this.ipaddress.getIpAddress().subscribe(
            res => {
              let user_seckey = JSON.parse(this._helper.getFromLocal("user_seckey"));
              let user_lkey = JSON.parse(this._helper.getFromLocal("user_lkey"));
              let my_ip;
      
              my_ip = res["ip"];
              let method = "POST";
              let end_point = "/api/get_work_summary/";
              let timestamp = new Date().toISOString();
              let dd = `${method}\n${my_ip}\n${end_point}\n${timestamp}\n${data}`;
              
              let hmac = CryptoJS.algo.HMAC.create(
                CryptoJS.algo.SHA256,
                `${user_seckey}`
              );
              hmac.update(dd);
              let hmacc = Base64.stringify(hmac.finalize());
              
              observer.next({
                h_macc: hmacc,
                u_lkey: user_lkey,
                timestamp: timestamp
              })
              observer.complete();
              
            },
            err => {
                observer.next(err)
                observer.complete();
            }
          );
    })

    
  }

  rca_hmac_gen(data) {

    return Observable.create(observer => {
        this.ipaddress.getIpAddress().subscribe(
            res => {
              let user_seckey = JSON.parse(this._helper.getFromLocal("user_seckey"));
              let user_lkey = JSON.parse(this._helper.getFromLocal("user_lkey"));
              let my_ip;
      
              my_ip = res["ip"];
              let method = "POST";
              let end_point = "/api/get_rcas/";
              let timestamp = new Date().toISOString();
              let dd = `${method}\n${my_ip}\n${end_point}\n${timestamp}\n${data}`;
              
              let hmac = CryptoJS.algo.HMAC.create(
                CryptoJS.algo.SHA256,
                `${user_seckey}`
              );
              hmac.update(dd);
              let hmacc = Base64.stringify(hmac.finalize());
              
              observer.next({
                h_macc: hmacc,
                u_lkey: user_lkey,
                timestamp: timestamp
              })
              observer.complete();
              
            },
            err => {
                observer.next(err)
                observer.complete();
            }
          );
    })

    
  }

  face_hmac_gen(data) {

    return Observable.create(observer => {
        this.ipaddress.getIpAddress().subscribe(
            res => {
              let user_seckey = JSON.parse(this._helper.getFromLocal("user_seckey"));
              let user_lkey = JSON.parse(this._helper.getFromLocal("user_lkey"));
              let my_ip;
      
              my_ip = res["ip"];
              let method = "POST";
              let end_point = "/api/facesearch/";
              let timestamp = new Date().toISOString();
              let dd = `${method}\n${my_ip}\n${end_point}\n${timestamp}\n${data}`;
              
              let hmac = CryptoJS.algo.HMAC.create(
                CryptoJS.algo.SHA256,
                `${user_seckey}`
              );
              hmac.update(dd);
              let hmacc = Base64.stringify(hmac.finalize());
              observer.next({
                h_macc: hmacc,
                u_lkey: user_lkey,
                timestamp: timestamp
              })
              observer.complete();
              
            },
            err => {
                observer.next(err)
                observer.complete();
            }
          );
    })

    
  }


}
