import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AppService } from '../app.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HelperService } from '../auth/_helpers/storage-helper';



@Injectable()
export class ApiService {
    private api = 'api';
    private watchlist = 'api/watchlists';
    private watch = 'api/watchlists/';
    private scrapper_status = 'api/watchlist_status/'
    private developer_status = 'api/watchlist_assignee/'
    private narcolist = 'api/crimetype'
    private serviceURIAUTH = 'auth';
    private scrapper_monitor = 'api/scrapper_monitor/'
    private post_delta = 'api/get_delta/'
    private validation_rule = 'api/validation_rules/'
    private watchlist_options = 'api/get_watchlist/'
    private watchlist_dropdown_options = 'api/watchlistoptions/'
    private developer_dropdown_options = 'api/developers/'
    private name_search = 'api/search/';
    private downloadprofile_ = 'api/generate_entity_report/'
    private org_search = 'api/search_entities/';
    private fetch_assets = 'api/get_assets/';
    private fetch_worksummary = 'api/get_work_summary/';
    private fetch_RCA = 'api/get_rcas/';
    private batch_file = 'api/batch_queue/';
    private batch_job_id = 'api/batch_queue_search/';
    public _config = environment;

    public url = this._config.LFD;


    private facescan_endpoint = 'api/facesearch/';
    apiUrl = 'https://api.pakistanidentitycheck.com/'
    // public faceurl = 'https://api.pakistanidentitycheck.com';
    constructor(private service: AppService, private http: HttpClient, private _helper: HelperService) { }

  
    getwatchlist(params: any): Observable<any[]> {
        return this.service.get(`${this.watchlist}`, params);
    }

    getcrimedata(data: any): Observable<any[]> {
        return this.service.get(`${this.api}/crimetype/` + data);
    }

    getdetails(params: number) {
        return this.service.get(`api/pep-details/` + params);
    }

    // faceScan(facedata) : Observable<any>  {
    //     //   return this.service.post(this.facescan, facedata);
    //     return this.http.post(`${this.faceurl}` + this.facescan, facedata, this._header()).pipe(
    //         map(this.extractData)
    //         , catchError(this.handleError));
    // }

    me(): Observable<any[]> {
        return this.service.get(`${this.serviceURIAUTH}/me/`);
    }

    postfacescan(params: any) {
        return this.service.post('/api/search_face', params);
      }

    namesearch(params: any) : Observable<any>{
        return this.service.post(this.name_search, params);
    }

    downloadprofile(params: any) : Observable<any>{
        return this.service.post_file(this.downloadprofile_ , params);
    }

    orgsearch(params: any) : Observable<any>{
        return this.service.post(this.org_search, params);
    }

    getscrappermonitorlist(param): Observable<any[]> {
        return this.service.get(`${this.scrapper_monitor}`, param);
    }

    postdelta(param): Observable<any[]> {
        return this.service.post(`${this.post_delta}`, param);
    }

    getvalidationrulelist(param): Observable<any[]> {
        return this.service.get(`${this.validation_rule}`, param);
    }

    getwatchlistoptions(param): Observable<any[]> {
        return this.service.get(`${this.watchlist_options}`, param);
    }

    getwatchlistdropdownoptions(param): Observable<any[]> {
        return this.service.get(`${this.watchlist_dropdown_options}`, param);
    }

    getdeveloperdropdownoptions(param): Observable<any[]> {
        return this.service.get(`${this.developer_dropdown_options}`, param);
    }


    putscrappermonitorlist(param, itemid): Observable<any[]> {
        return this.service.put(`${this.scrapper_monitor}`, itemid, param);
    }

    putvalidationrulelist(param, itemid): Observable<any[]> {
        return this.service.put(`${this.validation_rule}`, itemid, param);
    }

    putwatchlist(param): Observable<any[]> {
        return this.service.put1(`${this.watch}`, param);
    }

    putscarpperstatus(param): Observable<any[]> {
        return this.service.put1(`${this.scrapper_status}`, param);
    }

    putdeveloperstatus(param): Observable<any[]> {
        return this.service.put1(`${this.developer_status}`, param);
    }

    addvalidationrulelist(params: any): Observable<any[]> {
        return this.service.post(`${this.validation_rule}`, params);
    }

    addwatchlist(params: any): Observable<any[]> {
        return this.service.post(`${this.watch}`, params);
    }

    fetchassets(params: any) : Observable<any>{
        return this.service.post(this.fetch_assets, params);
    }

    fetchworksummary(params: any) : Observable<any>{
        return this.service.post(this.fetch_worksummary, params);
    }

    fetchRCA(params: any) : Observable<any>{
        return this.service.post(this.fetch_RCA, params);
    }

    fetchbatchjobbyid(params: any) : Observable<any>{
        return this.service.post(this.batch_job_id, params);
    }

    postFile(fileToUpload: File, params) {

            const endpoint = `${this.url}${this.batch_file}`;
            const formData: FormData = new FormData();
            formData.append("csv", fileToUpload);
            // formData.append('report_name', filetype);
            // formData.append('Content-Type', fileToUpload.type);
            // formData.append('enctype ', 'multipart/form-data');
            return this.http
            .post(endpoint, formData, this.t_header(params))
            .pipe(
                        map(this.extractData)
                        , catchError(this.handleError));
    }


    face_scan(item: any): Observable<any> {
        // return this.http.post(`${this.apiUrl}` + this.facescan_endpoint, item, { headers: this._header() }).pipe(
        //     map(this.extractData)
        //     ,catchError(this.handleError));
        return this.service.post(this.facescan_endpoint, item);

    }

    postreport(params: any) {
        return this.service.post('api/report_pep/', params);
      }


    _header(): HttpHeaders {
        let user = this._helper.getFromLocal('currentUser');
        if (!!user) {
            let temp = JSON.parse(user)
            let token = temp['token'];
            // const headers = new HttpHeaders({ 
            //     // 'Access-Control-Allow-Origin': 'http://localhost:4200',
            //     // 'Access-Control-Allow-Credentials': 'true',
            //     // 'Access- Control-Allow-Headers': 'X-Requested-With, Content-Type',
            //     // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE',
            //     'Content-Type': 'application/json; charset=utf-8',
            //     'Authorization': 'jwt ' + token });
            // const options = { headers: headers };
            // return options;
            return new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8', Authorization: 'jwt ' + token });
        }
    }

    t_header(itm) {
        let user = this._helper.getFromLocal('currentUser');
        if (!!user) {
            let temp = JSON.parse(user)
            let token = temp['token'];
            const headers = new HttpHeaders(
                { 
                    // 'Content-Type': 'application/json; charset=utf-8',
                    // 'Access-Control-Allow-Origin': 'http://localhost:4200',
                    // 'Access-Control-Allow-Credentials': 'true',
                    // 'Access- Control-Allow-Headers': 'X-Requested-With, Content-Type',
                    // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE',
                     'Authorization': 'jwt ' + token,
                    'Signature': itm.hmac_sig,
                    'Key': itm.u_key,
                    'timestamp': itm.timestamp,
                    'Pragma': 'no-cache',
                    'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate'  });
            const options = { headers: headers };
            return options;
        }
    }


    public extractData(res: Response) {
        let body = res;
        return body;
    }
    public handleError(error: Response | any) {
        return throwError(error);
    }
}


